<template>
    <v-container fluid>
        <v-dialog
            v-model="sampleSetDialog"
            max-width="500px"
            :fullscreen="$vuetify.breakpoint.xsOnly"
            persistent>
            <app-form
                :key="key"
                v-model="editedSampleSet"
                :schema="getSchema('SampleSets')"
                :filter="sampleSetFilter"
                @cancel="sampleSetDialog = false"
                @open="sampleSetDialog = true"
                @input="onInput()"
                @delete="onInput()" />
        </v-dialog>
        <v-dialog
            v-model="sampleSetTestDialog"
            max-width="500px"
            :fullscreen="$vuetify.breakpoint.xsOnly"
            persistent>
            <sample-set-test-form
                :key="key"
                v-model="editedSampleSetTest"
                :schema="getSchema('SampleSetTests')"
                :filter="sampleSetTestFilter"
                @cancel="sampleSetTestDialog = false"
                @open="sampleSetTestDialog = true"
                @input="onInput()"
                @delete="onInput()" />
        </v-dialog>
        <v-card>
            <v-card-title>
                <v-row>
                    <v-col cols="6">
                        Sample Sets
                    </v-col>
                    <v-col>
                        <v-btn-toggle
                            v-model="sampleToggle"
                            mandatory
                            class="ml-2 float-right"
                            tile
                            group>
                            <v-btn>
                                Active
                            </v-btn>
                            <v-btn>
                                Testing
                            </v-btn>
                            <v-btn>
                                Completed
                            </v-btn>
                        </v-btn-toggle>
                    </v-col>
                    <v-col>
                        <v-btn
                            v-if="canEdit('SampleSets')"
                            color="primary"
                            class="float-right"
                            dark
                            @click="sampleSetDialog = true">
                            Add Sample Set
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card-title>
            <v-container fluid>
                <v-row>
                    <v-col>
                        <div
                            class="align-top justify-top d-flex flex-nowrap"
                            style="overflow-x: auto; white-space: nowrap">
                            <sample-set
                                v-for="(sampleSet, i) in sampleSets"
                                :key="sampleSet.id"
                                v-model="sampleSets[i]"
                                :sample-set-tests="sampleSet.items"
                                @move="onMove"
                                @add="onAdd"
                                @edit="onEdit"
                                @input="load()" />
                        </div>
                    </v-col>
                </v-row>
            </v-container>
        </v-card>
    </v-container>
</template>

<script>
import axios from "axios"
import bucketPageService from "@/services/bucketPageService"
import { lessThan, equal, notEqual, and, greaterThan } from "@/services/filtering"
import dateUtility from "@/services/dateUtility"

export default {
    components: {
        appForm: () => import('@/components/AppForm'),
        sampleSet: () => import('./SampleSet'),
        sampleSetTestForm: () => import('@/forms/SampleSetTestForm')
    },
    props: {
        filter: {
            type: Object,
            default: null
        },
        refreshKey: {
            type: Number,
            default: null
        }
    },
    data() {
        return {
            sampleSets: [],

            editedSampleSet: null,
            sampleSetFilter: {},
            sampleSetDialog: false,
            key: 0,

            editedSampleSetTest: null,
            sampleSetTestFilter: {},
            sampleSetTestDialog: false,

            sampleToggle: 0
        }
    },
    computed: {
        toggleFilter() {
            if (this.sampleToggle === 0) {
                return greaterThan(
                    "SamplesToBeMailedOn",
                    dateUtility.getCurrentDateIso(),
                    "datetimeoffset");
            }
            if (this.sampleToggle === 1) {
                let now = dateUtility.getCurrentDateIso();
                return and([
                    lessThan("SamplesToBeMailedOn", now, "datetimeoffset"),
                    equal("CompletedOn", null, "datetimeoffset?")]);
            }
            if (this.sampleToggle === 2) {
                return notEqual("CompletedOn", null, "datetimeoffset?");
            }
            return null;
        }
    },
    watch: {
        $route() {
            this.load();
        },
        toggleFilter() {
            this.load();
        }
    },
    async mounted() {
        await this.load();
    },
    methods: {
        async load() {
            this.sampleSets = await bucketPageService.getSampleSetsWithTests(this.toggleFilter);
        },
        async onMove(event) {
            let sampleSetTest = event.sampleSetTest;
            sampleSetTest.sampleSetId = event.sampleSetId;

            try {
                await axios.put("/api/SampleSetTests", sampleSetTest);
            }
            catch (error) {
                this.handleError(error);
            }
            finally {
                await this.load();
            }
        },
        onAdd(sampleSetId) {
            this.editedSampleSetTest = null;
            this.editedSampleSet = null;
            this.key++;
            this.sampleSetTestFilter = { ...this.filter, sampleSetId };
            this.sampleSetTestDialog = true;
        },
        onEdit(sampleSetTest) {
            this.editedSampleSetTest = sampleSetTest;
            this.sampleSetTestFilter = { ...this.filter, sampleSetId: sampleSetTest.sampleSetId };
            this.sampleSetTestDialog = true;
        },
        async onInput() {
            this.editedSampleSetTest = null;
            this.editedSampleSet = null;
            this.sampleSetDialog = false;
            this.sampleSetTestDialog = false;
            this.key++;
            await this.load();
        }
    }
};
</script>
